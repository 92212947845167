import React from 'react';
import TemplateWrapper from '../layouts';

const IndexPage = () => (
  <TemplateWrapper>
    <div className="intro">
      <div>
        <h1>Morgane Bocquet</h1>
        <p>Fashion & Graphic Designer</p>
      </div>
      <hr />
      <div>
        <em>Website under construction</em>
        <span>See you soon</span>
      </div>
    </div>
  </TemplateWrapper>
);

export default IndexPage;
