import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import './index.css'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="Fashion & Graphic Designer — Morgane Bocquet"
      meta={[
        { name: 'description', content: 'Morgane Bocquet, Designer Textile et Mode. Création de motifs pour vêtements et accessoires. Diplômée d\'un Master de couleur image' },
        { name: 'keywords', content: 'morgane bocquet,designer textile,styliste' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
      ]}
    />
    <div className="main">
      {children}
    </div>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
